import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"

import { MDXRenderer } from "gatsby-plugin-mdx"
import s from "../projectStyles.module.scss"
import Fade from 'react-reveal/Fade'

class WebDevelopment extends Component {
   render() {
      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const footerContent = {
         C2As: [
            { text: "Next Project: Maeve Bespoke Lighting Rig", link: "projects/electronics" },
            {
               text: "Previous Project: BJ On The Daily",
               link: "projects/bjonthedaily",
            },
         ],
         styles: {
            backgroundColor: "transparent",
         },
      }

      return (
         <div className={`mainContainer ${s.webDevelopment}`}>
            <Layout
               title='Web Development'
               superTitle="Mr BJ Jackson / Projects"
               pageStyles={s}
               animationStatus={animationStatus}
               footerContent={footerContent}
               isProjectPage={true}
            >  
            <Fade>
               <p>Prior to my work as a producer I worked as a freelance web designer / developer.</p>

               <p>It's something I really enjoy and work on when I can. Though I've traditionally worked in php (I've probably built 40+ <a href="https://en-gb.wordpress.org/" title="Wordpress">Wordpress</a> and <a href="https://www.drupal.org/" title="Drupal">Drupal</a> sites), recently I've been really enjoying building sites using the <a href="https://reactjs.org/" title="React JS">React</a> Javascript library, normally using the <a href="https://www.gatsbyjs.org/" title="Gatsby JS">Gatsby framework</a>.</p>

               <h2>Some recent work</h2>

            </Fade>

               {
               this.props.data.allMdx.nodes.map(site => (
               
                  <div className="site">
                  <MDXRenderer frontmatter={site.frontmatter}>
                     {site.body}
                  </MDXRenderer>
                  </div>
              
               ))
               }
            </Layout>
         </div>
      )
   }
}

export default WebDevelopment

export const data = graphql`
   query {
      allMdx(filter: {frontmatter: {type: {eq: "site"}}}, sort: {fields: frontmatter___order}) {
        nodes {
          body
          frontmatter {
            title
          }
        }
      }
    }
`
